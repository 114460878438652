<template>
  <svg
    role="img"
    aria-labelledby="starRating"
    viewBox="0 0 251 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="starRating" lang="en">
      {{ score }} out of five star rating on Trustpilot
    </title>
    <g class="tp-star">
      <path
        class="tp-star__canvas"
        :fill="getFullStarFill(1)"
        d="M0 46.330002h46.375586V0H0z"
      />
      <path
        class="tp-star__canvas--half"
        :fill="getHalfStarFill(1)"
        d="M51.24816 46.330002h23.187793V0H51.248161z"
      />
      <path
        class="tp-star__shape"
        d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"
        fill="#FFF"
      />
    </g>
    <g class="tp-star">
      <path
        class="tp-star__canvas"
        :fill="getFullStarFill(2)"
        d="M51.24816 46.330002h46.375587V0H51.248161z"
      />
      <path
        class="tp-star__canvas--half"
        :fill="getHalfStarFill(2)"
        d="M51.24816 46.330002h23.187793V0H51.248161z"
      />
      <path
        class="tp-star__shape"
        d="M74.990978 31.32991L81.150908 30 84 39l-9.660206-7.202786L64.30279 39l3.895636-11.840666L58 19.841466h12.605577L74.499595 8l3.895637 11.841466H91L74.990978 31.329909z"
        fill="#FFF"
      />
    </g>
    <g class="tp-star">
      <path
        class="tp-star__canvas"
        :fill="getFullStarFill(3)"
        d="M102.532209 46.330002h46.375586V0h-46.375586z"
      />
      <path
        class="tp-star__canvas--half"
        :fill="getHalfStarFill(3)"
        d="M102.532209 46.330002h23.187793V0h-23.187793z"
      />
      <path
        class="tp-star__shape"
        d="M142.066994 19.711433L115.763298 38.80065l3.838215-11.797827-10.047304-7.291391h12.418975l3.837418-11.798624 3.837417 11.798624h12.418975zM125.81156 31.510075l7.183595-1.509576 2.862113 8.800152-10.045708-7.290576z"
        fill="#FFF"
      />
    </g>
    <g class="tp-star">
      <path
        class="tp-star__canvas"
        :fill="getFullStarFill(4)"
        d="M153.815458 46.330002h46.375586V0h-46.375586z"
        style=""
      />
      <path
        class="tp-star__canvas--half"
        :fill="getHalfStarFill(4)"
        d="M153.815458 46.330002h23.187793V0h-23.187793z"
      />
      <path
        class="tp-star__shape"
        d="M193.348355 19.711433L167.045457 38.80065l3.837417-11.797827-10.047303-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418974zM177.09292 31.510075l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
        fill="#FFF"
      />
    </g>
    <g class="tp-star">
      <path
        class="tp-star__canvas"
        :fill="getFullStarFill(5)"
        d="M205.064416 46.330002h46.375587V0h-46.375587z"
      />
      <path
        class="tp-star__canvas--half"
        :fill="getHalfStarFill(5)"
        d="M205.064416 46.330002h23.187793V0h-23.187793z"
      />
      <path
        class="tp-star__shape"
        d="M244.597022 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
        fill="#FFF"
      />
    </g>
  </svg>
</template>

<script lang="ts">
const TRUSTPILOT_NEUTRAL_COLOR = '#dcdce6';

/**
 * 2024 Displaying score system
 *
 * | Score from | Score until | Stars | Wording en | Wording de |
 * | ------- | ------ | ----- | --- | --- |
 * | 5.0 | 4.8 | 5 | Excellent | Hervorragend |
 * | 4.7 | 4.3 | 4.5 | Excellent | Hervorragend |
 * | 4.2 | 3.8 | 4.0 | Great | Gut |
 * | 3.7 | 3.3 | 3.5 | Average | Akzeptabel |
 */
export default defineComponent({
  props: {
    score: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const starsColor = computed(() => {
      if (props.score >= 4.3) {
        return '#00B67A';
      }

      if (props.score >= 3.8) {
        return '#73CF11';
      }

      // lets assume we do not get below 3.3 ;)
      return '#FFCE00';
    });

    const getFullStarFill = (index: number) => {
      if (props.score >= index - 0.2) {
        return starsColor.value;
      }

      return TRUSTPILOT_NEUTRAL_COLOR;
    };

    const getHalfStarFill = (index: number) => {
      if (props.score >= index - 0.7) {
        return starsColor.value;
      }

      return TRUSTPILOT_NEUTRAL_COLOR;
    };

    return {
      getFullStarFill,
      getHalfStarFill,
    };
  },
});
</script>

<style scoped></style>
